// Import from NPM
// -------------------------------------
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useFeatures } from "flagged";
import {
    useParams,
    useNavigate,
    useLocation,
    useSearchParams,
    NavLink,
} from "react-router-dom";
import { useGetBrandingQuery } from "@api/apiV6";
import abilityMap from "../../config/features/ability.json";

import _ from "lodash";
import {
    Input,
    Loader,
    Divider,
    Button,
    Image,
    Icon,
    Checkbox,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import Carousel from "@components/Carousel.react";
import { darkStyle, devMode } from "@utilities/helpers";

// Import from Config
// -------------------------------------
import { baseUrl } from "@api/apiV6";
import AuthLayout from "@layouts/AuthLayout.react";
import { Div } from "@components/Generics.react";
import { settings } from "@config/settings/app.settings";

// Import Components
// -------------------------------------
import { login } from "@reducers/authSlice";

export default function Login(props) {
    const { team } = useParams();
    const { data: branding } = useGetBrandingQuery(team || "Company");

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [termsChecked, setTermsChecked] = useState(false);
    const [error, setError] = useState(null);
    const dispatch = useDispatch();
    const features = useFeatures();
    const navigate = useNavigate();
    const { t } = useTranslation("common");
    const { state } = useLocation();

    const [search] = useSearchParams();
    const [token, setToken] = useState(search.get("token"));
    const omniauthData = settings.omniauth;
    const wPageHt = window.innerHeight - 100;
    const isPortrait = window.innerHeight > window.innerWidth;

    const shouldRenderOmniauthButton =
        omniauthData &&
        omniauthData.length > 0 &&
        omniauthData[0].url &&
        omniauthData[0].text;

    const tryLogin = async () => {
        try {
            let formData = new FormData();
            formData.append("username", username);
            formData.append("password", password);
            const response = await axios({
                method: "post",
                url: `${baseUrl}api/v3/auth/sign_in`,
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
            });
            let brandingAdjustedUser = _.cloneDeep(response.data);
            // Set default branding if undefined or null
            brandingAdjustedUser.branding = brandingAdjustedUser.branding || {};
            brandingAdjustedUser.branding.colors = brandingAdjustedUser.branding.colors || {
                main: settings.colors.main,
                primary: settings.colors.primary,
                primaryText: settings.colors.primaryText,
            };
            await dispatch(
                login({
                    // user: response.data,
                    user: brandingAdjustedUser,
                    token: response.headers["access-token"],
                })
            );
            window.localStorage.setItem("firstLogin", "done");
            if (team != null)
                window.localStorage.setItem("team", response.data.team);
                    navigate(
                        !isPortrait &&
                            features.general.builderRouting &&
                            _.includes(
                                abilityMap.builder.visible,
                                response.data.license.license_type
                            )
                            ? "/builder"
                            : state?.from?.pathname || "/"
                    )
        } catch (error) {
            console.log(error);
            setError(error.response?.data?.message);
        }
    };

    const tokenLogin = async () => {
        try {
            const response = await axios({
                method: "post",
                url: `${baseUrl}api/v2/flipkart/auth/connect`,
                headers: { authtoken: token },
            });
            let brandingAdjustedUser = _.cloneDeep(response.data);
            // Set default branding if undefined or null
            brandingAdjustedUser.branding = brandingAdjustedUser.branding || {};
            brandingAdjustedUser.branding.colors = brandingAdjustedUser.branding.colors || {
                main: settings.colors.main,
                primary: settings.colors.primary,
                primaryText: settings.colors.primaryText,
            };
            await dispatch(
                login({
                // user: response.data,
                user: brandingAdjustedUser,
                token: response.headers["access-token"],
            })
        );
            let redirectPath = localStorage.getItem("redirectPath");
            if (redirectPath) {
                redirectPath =
                    redirectPath !== "/" && redirectPath !== "/logout"
                        ? `${
                              devMode
                                  ? ""
                                  : settings.targetRoute.slice(0, -1)
                          }${redirectPath}`
                        : "/";
            }
            const redirectUrl = redirectPath || "/";
            localStorage.removeItem("redirectPath");
            window.location.href = redirectUrl;
        } catch (error) {
            setError("Invalid Token Provided!");
            setToken(null);
        }
    };    

    const handleOmniauthClick = (url) => {
        window.location.href = url;
    };

    useEffect(() => {
        (async () => {
            if (search && search.get("token") != null) {
                if (
                    settings.functionality.guestsActive &&
                    search.get("token") === "goGuest"
                ) {
                    window.location.href = `/guest/login${
                        features.tenanting.autoGuests ? "?auto=true" : ""
                    }`;
                } else {
                    await tokenLogin();
                }
            } else setToken(null);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const loginBox = (
        <Div
            fluid
            superpad
            ht={isPortrait ? wPageHt : "100%"}
            flex
            centered
            maxWd="480px"
            key="login-box"
        >
            <Div wd="60%" gutter>
                <Image src={branding?.image} />
            </Div>
            <Div fluid white fullHt superpad rounded>
                <Div massive altText fluid center-align gutter>
                    SIGN IN
                </Div>
                {error && (
                    <Div padded fluid danger gutter>
                        {error}
                    </Div>
                )}
                <Div fluid gutter>
                    <Input
                        fluid
                        label={t("auth.loginPage.usernameLabel")}
                        placeholder={t("auth.loginPage.usernamePlaceholder")}
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <br />
                    <Input
                        fluid
                        label={t("auth.loginPage.password")}
                        type={showPassword ? "text" : "password"}
                        placeholder={t("auth.loginPage.passwordPlaceholder")}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        icon={
                            <Icon
                                name={showPassword ? "eye slash" : "eye"}
                                link
                                onClick={() => setShowPassword(!showPassword)}
                            />
                        }
                    />
                    <br />
                    {settings.functionality.hasTerms && (
                        <Div flex fluid gutter>
                            <Checkbox
                                label={
                                    <label>
                                        {t("auth.terms.termsAndConditions")}{" "}
                                        <a
                                            href={t("auth.terms.link")}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {t("auth.terms.linkText")}
                                        </a>
                                    </label>
                                }
                                checked={termsChecked}
                                onChange={() => {
                                    setTermsChecked(!termsChecked);
                                }}
                            />
                        </Div>
                    )}
                    <Button
                        fluid
                        primary
                        content={t("auth.login")}
                        disabled={
                            settings.functionality.hasTerms && !termsChecked
                        }
                        onClick={tryLogin}
                    />
                    <br />
                    {settings.functionality.smtpActive && (
                        <NavLink to="/forgot">{t("auth.forgot")}</NavLink>
                    )}
                    <Div padTop flex column gapped clickable>
                        {settings.socialauth.length > 0 &&
                            _.map(settings.socialauth, (btn, idx) => {
                                return (
                                    <Image
                                        key={`social-auth-${idx}`}
                                        fluid
                                        src={btn.image}
                                        onClick={() => {
                                            const redirectUrl = btn.url
                                                ? settings.baseUrl + btn.url
                                                : null;
                                            if (redirectUrl) {
                                                window.location.href =
                                                    redirectUrl;
                                            }
                                        }}
                                    />
                                );
                            })}
                    </Div>
                    {settings.functionality.registrationActive && (
                        <Div center-align>
                            <Button.Group>
                                <NavLink
                                    to={
                                        team == null
                                            ? "/register"
                                            : `/${team}/register`
                                    }
                                >
                                    <Button color="teal">
                                        <Div txtWhite>{t("auth.register")}</Div>
                                    </Button>
                                </NavLink>
                                {settings.functionality.guestsActive && (
                                    <>
                                        <Button.Or />
                                        <NavLink
                                            to={`${
                                                team == null
                                                    ? "/guest/login"
                                                    : `/${team}/guest/login`
                                            }${
                                                features.tenanting.autoGuests
                                                    ? "?auto=true"
                                                    : ""
                                            }`}
                                        >
                                            <Button color="teal">
                                                <Div txtWhite>
                                                    {t("auth.guest")}
                                                </Div>
                                            </Button>
                                        </NavLink>
                                    </>
                                )}
                            </Button.Group>
                        </Div>
                    )}
                    {shouldRenderOmniauthButton && <Divider />}
                    {shouldRenderOmniauthButton && (
                        <Button
                            fluid
                            icon
                            labelPosition="left"
                            onClick={() =>
                                handleOmniauthClick(omniauthData[0].url)
                            }
                        >
                            <i
                                className={`icon ${
                                    omniauthData[0].icon || "users"
                                }`}
                            />
                            {omniauthData[0].text}
                        </Button>
                    )}
                </Div>
            </Div>
        </Div>
    );
    const hasLoggedIn = window.localStorage.getItem("firstLogin") != null;
    // ========================= Render Function =================================
    if (token != null) return <Loader active>Logging in...</Loader>;
    else if (
        team == null ||
        branding == null ||
        branding.cards == null ||
        branding.cards?.length === 0 ||
        features.general.noWelcome
    ) {
        return (
            <AuthLayout
                title={`Login to ${t("appName")}`}
                team={team}
                teamLogin={true}
            >
                {loginBox}
            </AuthLayout>
        );
    } else {
        return (
            <AuthLayout
                title={`Login to ${t("appName")}`}
                team={team}
                teamLogin={true}
            >
                <Div centered maxWd="360px">
                    <Carousel
                        lazyLoad
                        slidesToShow={1}
                        dots
                        initialSlide={
                            hasLoggedIn ? branding.cards?.length + 1 : 0
                        }
                        children={_.flatten([
                            <Div
                                superpad
                                fluid
                                flex
                                ht={`${wPageHt - 20}px`}
                                key="brand-splash"
                            >
                                <Image src={branding?.image} />
                            </Div>,
                            _.map(branding?.cards, (page, idx) => {
                                return (
                                    <Div
                                        fluid
                                        superpad
                                        big
                                        ht={`${wPageHt - 20}px`}
                                        key={`wpage-${idx}`}
                                        style={{ color: branding?.textColor }}
                                    >
                                        <Div rounded noOverflow gutter>
                                            <Image src={page.image} />
                                        </Div>
                                        <Div
                                            big
                                            bold
                                            gutter
                                            gapTop
                                            style={darkStyle(
                                                branding?.mainColor,
                                                "to top right"
                                            )}
                                            medpad
                                            rounded
                                        >
                                            {page.header}
                                        </Div>
                                        <Div trench>{page.content}</Div>
                                    </Div>
                                );
                            }),
                            loginBox,
                        ])}
                    />
                </Div>
            </AuthLayout>
        );
    }
}
